import { TableColumns } from '@ff-it/ui';
import { ModelScene, IndexScene } from 'components';
import { never } from 'core/permissions';

interface Conflict {
  code: string;
  message: string;
}

interface ExpenseMapping {
  id: number;
  meta_invoice_number: string | null;
  conflicts: Conflict[];
}

const model = {
  endpoint: 'invoicing/expense/conflicts',
  title: 'Conflict',
  pluralTitle: 'Synchronization Conflicts',
  entityTitle: (e: ExpenseMapping) => e.meta_invoice_number,
};

const columns: TableColumns<ExpenseMapping> = [
  {
    content: 'Number',
    key: 'meta_invoice_number',
  },
  {
    content: 'Conflicts',
    key: 'message',
    render(data, row, cell) {
      return (
        <ul>
          {data.conflicts.map((conflict, idx) => (
            <li key={`${data.id}:idx`}>{conflict.message}</li>
          ))}
        </ul>
      );
    },
  },
];

export const route = {
  path: 'conflicts',
  element: <ModelScene {...model} />,
  children: [
    {
      index: true,
      element: <IndexScene canCreate={never} columns={columns} />,
    },
  ],
};
